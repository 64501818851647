#items .form-inline .form-control {
    width: 100%;
}
#items td.delete {
    text-align: right;
    padding-top: 11px!important;
}
#items td:first-child {
    min-width: 130px;
}
#items td:nth-child(3) {
    min-width: 70px;
}
#items td:nth-child(4) {
    min-width: 60px;
}
#items td:nth-child(7) {
    min-width: 78px;
}
#items .totalSumDesc {
    border-left: 1px solid rgb(221, 221, 221);
}
#items .row:not(:nth-child(2)) {
    display: none;
}
#items table.jambo_table tbody tr.total:hover td {
    background: inherit;
    border-color: inherit;
}
#items th:nth-last-of-type(3):before {
    background-color: white;
}
