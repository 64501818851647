.posr {
    position: relative;
}

.disn {
    display: none;
}

.wida {
    width: auto;
}

.mart-20 {
    margin-top: 20px;
}

.bold {
    font-weight: bold;
}

body {
    background: white!important;
}

.right_col {
    min-height: auto!important;
}

.left_col.menu_fixed {
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10!important;
}

.nav-sm .container.body .col-md-3.left_col {
    width: 70px!important;
}

.nav-md .container.body .col-md-3.left_col {
  width: 235px;
}

.site_title img {
    max-height: 57px;
}

.img-circle.profile_img.user-subst {
    width: 45px;
    height: 45px;
    margin-top: 25px;
}

.user-subst i {
    font-size: 28px;
    line-height: 35px;
    width: 35px;
    text-align: center;
}

.top_nav .user-subst {
    float: left;
    height: 29px;
    background: white;
    border-radius: 50%;
    margin-right: 5px;
}

.top_nav .user-subst i {
    width: 29px;
    line-height: 29px;
}

.nav.navbar-nav>li>a.fa, .nav.navbar-nav>li>a.menu-sign {
    font-size: 18px;
}

.nav.navbar-nav>li>a.active {
    background-color: #d9dee4;
}

div.left_col .navbar {
    display: none;
}

.side-menu .fa {
    vertical-align: middle;
}

.nav-md .sidebar-footer {
    z-index: 1;
    padding: 0;
    width: 235px !important;
}

.nav-sm .hidden-small {
    visibility: hidden;
    width: 0;
    position: absolute;
}

.nav-sm .sidebar-footer {
    z-index: 1;
    padding: 0;
    width: 70px !important;
}

.label-success {
    background-color: #5cb85c;
}
.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #449d44;
}
.label-info {
    background-color: #5bc0de;
}
.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #31b0d5;
}
.label-warning {
    background-color: #f0ad4e;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color: #ec971f;
}
.label-danger {
    background-color: #d9534f;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #c9302c;
}
.label-error {
    background-color: #d9534f;
}
.label-error[href]:hover,
.label-error[href]:focus {
    background-color: #c9302c;
}

.ui-pnotify-container::before {
    content: "\e014";
    position: absolute;
    top: 2px;
    right: 2px;
    display: inline;
    font-family: 'Glyphicons Halflings';
    font-size: 10px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sidebar-footer table {
    width: 100%;
}

.sidebar-footer a {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.notif-number {
    position: absolute;
    top: 5px;
    left: 14px;
    background: #E7E7E7;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #172d44;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
    padding: 0px 3px 1px 3px;
    min-width: 15px;
    line-height: 15px;
}

.side-calendar {
    border: 1px solid #515356;
    border-radius: 4px;
    text-align: center;
    margin: 9px;
    position: relative;
}

.side-calendar:before, .side-calendar:after {
    content: "";
    background: #515356;
    width: 3px;
    height: 4px;
    position: absolute;
    top: -5px;
}

.side-calendar:before {
    left: 4px;
}

.side-calendar:after {
    right: 4px;
}

.side-calendar .month {
    background-color: #515356;
    line-height: 13px;
    color: white;
    padding: 1px 4px;
}

.side-calendar .day {
    color: #515356;
}

.dropdown-menu {
    min-width: inherit;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.flex-item {
    width: 270px;
    margin: 0 15px 0 0;
}

.flex-item label {
    float: left;
    margin-top: 9px;
    margin-right: 4px;
    width: 125px;
    word-wrap: break-word;
    font-size: 14px;
}

.selectFilters label {
    float: none;
    margin-top: 5px;
}

.btn-group.button-filter {
    float: left;
    margin-right: 5px;
}

.flex-container-form .flex-item {
    width: auto;
}

#filterUrl {
    margin-bottom: 15px;
}

.filter-buts .button-filter .btnFilter {
    padding-left: 5px;
    padding-right: 5px;
}

.filter-buts .button-filter .btnRemove {
    padding-left: 1px;
    padding-right: 1px;
}

/* in module-setting*/

.flex-original-columns, .flex-other-columns {
    max-width: 325px;
}

.flex-container>.flex-container>.flex-item {
    width: 147px;
}

#module-setting .flex-container label {
    width: auto;
    padding-left: 0;
}

#module-setting .select-all {
    margin-top: 6px;
}

.select-rows {
    flex: 0 1 100%;
    margin-top: 15px;
}

.select-rows .flex-item,
.select-refresh-interval .flex-item{
    width: 300px!important;
}

.item-buts {
    margin-top: 15px;
}

.dsl-panel-item span.delete-icon {
    padding: 1px 5px;
}

.dsl-panel-item span.delete-icon:hover {
    background-color: aliceblue;
}

/* /in module-setting*/

.x_title h2 {
    line-height: 22px;
}


/*TABLE*/

.table-responsive {
    border: none!important;
}

.table {
    width: 100%!important;
}

table.jambo_table {
  border: 0px;
}

table.jambo_table thead {
    opacity: 1;
}

table.jambo_table thead th {
    min-width: 60px;
}

th {
    position: relative;
}

td:empty {
    max-width: 10px!important;
}

table th a {
    color: #ecf0f1;
    cursor: pointer;
}

table th a:hover, table th a:focus {
    color: rgba(236, 240, 241, 0.8);
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th {
    vertical-align: middle;
}

table p {
    margin: 0;
}

.row-actions {
    text-align: right;
}

table a.fa, .row-actions>a span {
    font-size: 18px;
    line-height: 15px;
    cursor: pointer;
}

th a.fa-gear {
    margin-top: -1px;
}

.datagridExportMenu {
    min-width: inherit;
    right: 4px;
    left: inherit;
}

.datagridExportMenu.open, .dropdownActionsMenu.open {
    display: block;
}

.dropdownActionsMenu {
    left: calc(100% - 200px);
    width: 200px;
    top: 60%;
    padding: 0 !important;
    z-index: 1002;
}

.dropdownActionsMenu span::before {
    margin-right: 10px;
}

.datagridExportMenu2 {
    min-width: inherit;
    right: 4px;
    left: inherit;
    z-index: 5;
}

.datagridExportMenu2.open {
    display: block;
}

.row-actions>a {
    margin-left: 5px;
    margin-right: 0;
    text-align: center;
    min-width: 18px;
}

.row-actions ul {
    padding: 9px 0;
}

div.divContextMenu ul {
    padding: 9px 0;
}

.row-actions ul a {
    margin-left: 0;
}

.sorting_disabled {
    padding-right: 8px!important;
}

.sorting_disabled:after {
    display: none!important;
    pointer-events: none;
}

.displaySwitch {
    float: right;
    font-size: 18px;
}

.table-top-actions {
    margin-bottom: 4px;
}

input#fulltextSearch {
    margin-left: 5px;
}

tfoot input {
    margin: 0;
    width: auto;
}


/*sorting*/

thead>tr>th.sorting_asc a, thead>tr>th.sorting_desc a, thead>tr>th.sorting a, thead>tr>td.sorting_asc a, thead>tr>td.sorting_desc a, thead>tr>td.sorting a {
    /*padding-right: 30px*/
}

thead>tr>th.sorting_asc i, thead>tr>th.sorting_desc i, thead>tr>th.sorting i, thead>tr>td.sorting_asc i, thead>tr>td.sorting_desc i, thead>tr>td.sorting i {
    /*margin-left: -30px*/
}

thead .sorting, thead .sorting_asc, thead .sorting_desc, thead .sorting_asc_disabled, thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative
}

thead .sorting a:after, thead .sorting_asc a:after, thead .sorting_desc a:after, thead .sorting_asc_disabled a:after, thead .sorting_desc_disabled a:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: 'Glyphicons Halflings';
    opacity: 0.5
}

thead a.helpTooltip i {
    position: absolute;bottom: 16px;transform: translateY(-100%);right: 8px;
}
thead a.helpTooltip:after {
    display: none;
}

thead.useHelpHeight {height: 50px;}

thead .sorting a:after {
    opacity: 0.2;
    content: "\e150";
}

thead .sorting_asc a:after {
    content: "\e155";
}

thead .sorting_desc a:after {
    content: "\e156";
}

thead .sorting_asc.multiOrdering a:after, thead .sorting_desc.multiOrdering a:after {
    color: #f0ad4e !important;
}

thead .sorting_choose ul a:after {
    color: #00b410;
}

thead .sorting_choose ul a.sorting_desc:after,
thead .sorting_choose a.choose_sorting_desc:after {
    content: "\e156";
    color: #00b410;
}

thead .sorting_choose ul a.sorting_asc:after,
thead .sorting_choose a.choose_sorting_asc:after {
    content: "\e155";
    color: #00b410;
}

thead .sorting_choose ul a.sorting:after,
thead .sorting_choose a.choose_sorting:after{
    content: "\e150";
    color: inherit !important;
}

thead .sorting_asc_disabled a:after, thead .sorting_desc_disabled a:after {
    color: #eee;
}

thead .sorting .dropdown-menu li a:after,
thead .sorting_asc .dropdown-menu li a:after,
thead .sorting_desc .dropdown-menu li a:after {
    position: relative;
    bottom: 0;
    right: 0;
    float: right;
    opacity: 1;
}

thead .dropdown-menu-choose {
    left: calc(100% - 140px);
    min-width: 140px;
    max-width: 140px;
}

div#orderingSelector {
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
}

div#orderingSelector div {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 20%;
    margin-right: 40px;
    padding: 0px;
}

ul.sortable {
    border: #ccc 1px solid;
    height: 100%;
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

ul.sortable li {
    padding: 5px;
    cursor: pointer;
    background-color: #f2f5f7;
    margin: 2px;
}

ul.sortable li:hover {
    background-color: #ccc;
}

/*HALF-BORDERED*/

.table>thead>tr>th {padding-right: 30px;}
.table>thead>tr>th, .table>tbody>tr>td, .table>tfoot>tr>th {
    position: relative;
}

.table th:before, .table td:after {
    content: "";
    height: 80%;
    width: 2px;
    position: absolute;
    top: 10%;
    right: 0;
}

.table th:before {
    background-color: white;
}

.table:not(.editable-datagrid) th.icons-column:before {
    background-color: unset;
}

.table td:after, .table tfoot th:before {
    background-color: #c7c7c7;
}

.table tr.highlight td:after {
    background-color: white;
}

.table th.grid-col-check:before, .table td.grid-col-check:after,
.table th:last-child:before,
.table th:last-of-type:before,
.table td:last-child:after,
.table tfoot th:nth-last-child(2):before {
    width: 0;
}

.table>tbody>tr>td.form-group:after {
    display: block;
}

.tiles-bulk-actions {
  display: flex;
  background: #f5f5f5;
}

.tiles-bulk-checkbox {
  width: 7%;
  float: left;
  display: inline-flex;
}

.export-menu-tiles {
  position: absolute;
  margin-top: 15px;
}

/*FILTER*/

.filter-body, .ordering-body, .quick-filter {
    display: none;
}

#filter-list {
    margin-top: -5px;
    min-width: 250px;
}

@media (max-width: 991px) {
    .filter-in-but {
        float: none!important;
    }
}

.filter-foot * {
    vertical-align: baseline;
    font-size: 14px;
}

.filter-input-group {
    width: 100%;
}

#filter-form label, #ordering-form label {
    width: 100%;
}

.callout-error {
    color: #e74c3c;
}


/*FORMS*/

.form-control {
    margin-bottom: 4px;
}

table .form-control {
    margin-bottom: 0;
}

.help-block.text-danger {
    color: #a94442;
}

div.form-group div.has-feedback {
    margin-bottom: 0;
    float: left;
}

div.filter-options div.has-feedback {
    width: 100%;
}

.form-control-feedback.right {
    right: 0!important;
    z-index: 1;
    font-size: 15px !important;
}

span.fa.form-control-feedback.right {
    right: 13px !important;
}

div.filter-options span.fa.form-control-feedback.right {
    right: 0px !important;
}

div.filter-options span.fa.form-control-feedback.left {
    left: 0px !important;
}

span.fa.form-control-feedback.rightSelectBox {
    right: 23px !important;
}

span.select2-selection__rendered.iconPaddingLeft, ul.select2-selection__rendered.iconPaddingLeft {
    padding-left: 45px !important;
}

.form-horizontal .input-group[class*=col-] {
    padding: 0 10px;
}

input[type='text'].entitiesForm {
    border-right: 0;
}

.input-group-addon.danger {
    color: rgb(255, 255, 255);
    background-color: rgb(217, 83, 79);
    border-color: rgb(212, 63, 58);
    cursor: pointer;
}

.input-group-addon.info {
    color: rgb(255, 255, 255);
    background-color: #5bc0de;
    border-color: #46b8da;
    padding: 0;
}

.input-group-addon.info input {
    border: 0;
    background: transparent;
    color: white;
    box-shadow: none;
    height: 20px;
    padding: 6px 12px;
    height: 27px;
    margin-right: 0;
}

.input-group-addon.info input.buttonAdd {
    font-weight: bolder;
    font-size: 26px;
    padding: 0px 10px;
    border-left: 1px solid white;
}

.input-group-btn .btn {
    margin-bottom: 0;
    margin-right: 0;
}

.select2, .select2-dropdown, .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #555!important;
    font-size: 14px!important;
}

.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
    min-height: 34px;
}

select.select2, .dropzone input[type="file"] {
    display: none;
}

form .select2-container {
    width: 100%!important;
}

form select[readonly].select2-hidden-accessible + .select2-container {
    pointer-events: none;
    touch-action: none;
}

form select[readonly].select2-hidden-accessible + .select2-container .select2-selection {
    background: #eee;
    box-shadow: none;
}

form select[readonly].select2-hidden-accessible + .select2-container .select2-selection__clear {
    display: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-top: 2px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: -2px;
}

.select-rows .select2 {
    width: auto!important;
    float: right;
}

div.bulk-check {
    position: relative;
}

div.tile .icheckbox_flat-green, div.bulk-check .iradio_flat-green {
    position: absolute !important;
    right: 0;
}

.icheckbox_flat-green, .iradio_flat-green {
    top: 5px;
    margin-right: 5px;
    vertical-align: baseline;
}

.profile-user-noimg {
    font-size: 150px;
    display: block;
}

table.jambo_table tbody tr:hover td.dataTables_empty {
    background: inherit;
    border-color: inherit;
}

div.dataTables_wrapper div.dataTables_filter {
    width: auto;
}


/*required pro entitiesForm*/

.has-error>div.input-group {
    margin-bottom: 30px;
}

.input-group .help-block {
    position: absolute;
    left: 0px;
    top: 35px;
}

address {
    margin-bottom: 0;
}


/*dropzone*/

.dropzone {
    min-height: 200px;
}

.dropzone .dz-preview {
    padding: 35px 6px 20px;
    margin: 0;
}

.dropzone .dz-preview .dz-image {
    width: 80px;
    height: 80px;
    border-radius: 0;
}

.dropzone .dz-preview .dz-image img {
    height: 100%;
    margin: auto;
}

.dropzone .dz-preview .dz-details {
    opacity: 1;
    padding: 45px 8px 15px;
    color: inherit!important;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
    background-color: white!important;
}

.dropzone .dz-preview .dz-filename {
    position: relative;
    top: -66px;
    text-align: left;
}

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-filename:hover span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.dropzone .dz-preview .dz-size {
    opacity: 0;
    font-size: 14px!important;
}

.dropzone .dz-preview:hover .dz-size {
    opacity: 1;
}

.dropzone .dz-preview .dz-remove {
    padding-top: 5px;
}

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
    background: white;
    padding: 4px;
}

.drag_upload {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
}

.drag_upload .dropzone {
    flex-grow: 1;
    height: 49%;
    margin: 1px;
    text-align: center;
    position: relative;
}

.drag_upload .dropzone>p>span {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.drag_upload .dropzone>p {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    width: 100%;
}

/*hotKeys form*/

form div.dummy-input {
    background-color: #fff;
    border: 1px solid #ccc;
    min-height: 40px;
    padding: 1px;
}

form div.dummy-input div.hotkeyButton {
    display: inline-block;
    position: relative;
    margin-right: 15px;
}

form div.dummy-input .btn {
    margin: 1px;
}

form div.dummy-input a.deleteButton {
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    right: -10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

/*widgets*/

.widget-tab ul {
    list-style-type: none;
    padding-left: 0;
}

.widget-tab .dot {
    margin-right: 10px;
}

.widget-tab .panel_toolbox>li {
    float: right;
}

.widget-tab .x_title h2 {
    width: calc(100% - 70px);
    white-space: inherit;
}

.widget-tab .x_content {
    overflow: auto;
}

.widget-tab .fc-icon {
    height: auto;
    top: 3px!important;
}

.fc-day-grid-container {
    overflow: hidden!important;
}
/*tiles*/

.tiles-view .table-top-actions {
    clear: both;
}

.tiles-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.tile {
    background: #f5f5f5;
    padding: 15px;
    margin: 10px 10px 10px 0;
    width: 430px;
    border-left: 5px solid transparent;
}

.tile dt, .tile dd {
    float: left;
}

.tile dt {
    width: 130px;
    clear: both;
}

.tile dd {
    width: 265px;
}

.tileActions {
    padding-top: 5px;
    clear: both;
    float: left;
}

.tileActions span {
    color: white;
    background: #5a738e;
    font-size: 16px;
    padding: 4px 8px;
    margin-top: 2px;
    border-radius: 4px;
}

.tileActions span:hover {
    background: #23527c;
}


/*PREVIEW*/

.preview .flex-container-form {
    justify-content: flex-start!important;
    display: flex;
    flex-wrap: wrap;
}

.preview .flex-container-form .flex-item {
    padding: 20px;
    background: #f5f5f5;
    margin: 0 10px 10px 0;
    overflow: auto;
}

@media (max-width: 550px) {
    .preview .flex-container-form .flex-item {
        width: 100%;
    }
}

.preview h2 {
    margin-bottom: 15px;
    text-transform: uppercase;
}

.preview .flex-item dt, .preview .flex-item dd {
    float: left;
    padding: 3px 3px 3px 0;
}

.preview .flex-item dt {
    width: 125px;
    clear: left;
}


/*legend and pagination*/

.tile.legend_gray, .preview .tiles-wrapper .legend_gray {
    border-left: 5px solid #c7c7c7;
}

.tile.legend_green, .preview .tiles-wrapper .legend_green {
    border-left: 5px solid #00b410;
}

.tile.legend_darkblue, .preview .tiles-wrapper .legend_darkblue {
    border-left: 5px solid #00616f;
}

.tile.legend_orange, .preview .tiles-wrapper .legend_orange {
    border-left: 5px solid #fd8e0c;
}

.tile.legend_red, .preview .tiles-wrapper .legend_red {
    border-left: 5px solid #e1312d;
}

.tile.legend_azure, .preview .tiles-wrapper .legend_azure {
    border-left: 5px solid #4ccfd9;
}

.left-legend {
    float: left;
    line-height: 34px;
}

.datagrid-legends {
    float: right;
}

.datagrid-legends>span {
    color: #c7c7c7;
    padding-right: 6px;
}

.pagi-count~.datagrid-legends span {
    display: inline;
}

.datagrid-legends .label {
    font-weight: normal;
    line-height: 22px;
    color: inherit;
    padding: 0 2px 0 0;
    font-size: 13px;
    white-space: pre;
}

.datagrid-legends span.fa {
    margin-right: -2px;
    font-size: 13px;
}

.datagrid-legends .legend_black span {
    color: #2A3F54;
}

.datagrid-legends .legend_gray span {
    color: #c7c7c7;
}

.datagrid-legends .legend_green span {
    color: #00b410;
}

.datagrid-legends .legend_darkblue span {
    color: #00616f;
}

.datagrid-legends .legend_orange span {
    color: #fd8e0c;
}

.datagrid-legends .legend_red span {
    color: #e1312d;
}

.datagrid-legends .legend_azure span {
    color: #4ccfd9;
}

.datagrid-legends .legend_info span {
    color: #5bc0de;
}

.datagrid-legends .legend_success span {
    color: #5cb85c;
}

.datagrid-legends .legend_defaut span {
    color: #777;
}

.gridColUserAvatar i.fa {
    text-align: center; font-size: 18px; height: 32px;
    width: 32px;
    float: left;
    display: inline-block;
    border-radius: 2px;
    padding: 6px 2px 2px 2px;
    background: #f7f7f7;
    border: 1px solid #e6e6e6;
}

.gridColUserAvatar small {
    display: inline-block;
    padding: 8px 0px 0px 5px;
}

.table th:first-child, .table td:first-child {
    position: relative;
    padding-left: 13px;
}

.table td:first-child:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background: #2A3F54;
    opacity: 0.9;
}

.table tr.legend_gray td:first-child:before {
    background: #c7c7c7;
}

.table tr.legend_green td:first-child:before {
    background: #00b410;
}

.table tr.legend_darkblue td:first-child:before {
    background: #00616f;
}

.table tr.legend_orange td:first-child:before {
    background: #fd8e0c;
}

.table tr.legend_red td:first-child:before {
    background: #e1312d;
}

.table tr.legend_azure td:first-child:before {
    background: #4ccfd9;
}

.table tr.legend_info td:first-child:before {
    background: #5bc0de;
}

.table tr.legend_success td:first-child:before {
    background: #5cb85c;
}

.table tr.legend_default td:first-child:before {
    background: #777;
}

.grid-paginator {
    float: right;
    padding-bottom: 1px;
}

.dataTables_paginate div {
    display: inline-block;
}

.pagination {
    margin: 0;
}

.pagi-items {
    vertical-align: top;
    margin: 0 10px;
    float: left;
}

.pagi-items .select2-container--default .select2-selection--single {
    min-height: 32px;
}

.pagi-items .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-top: 2px;
}

.pagi-count {
    display: inline-block;
    margin-right: 10px;
    line-height: 22px;
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    background: #ddd!important;
}

.pagi-items-text {
    line-height: 34px;
    vertical-align: top;
}

@media (max-width: 1100px) {
    .datagrid-legends {
        float: left;
    }
    .datagrid-legends>span {
        display: none!important;
    }
    .pagi-items {
        margin-left: 0;
    }
    .pagi-count {
        display: block;
    }
    .left-legend {
        margin-top: 40px;
    }
    .grid-paginator {
        position: absolute;
        top: 5px;
    }
}


/*UI dialog like modal dialog*/

.ui-widget, .ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: "Helvetica Neue", "Roboto", Arial, "Droid Sans", sans-serif!important;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited, .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, .ui-widget-header a, .ui-widget-content a {
    color: #73879C;
}

.ui-dialog {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    padding: 0;
    z-index: 1003;
}

.ui-widget-header {
    border: 0;
    background: #e9e9e9;
    color: #73879C;
    padding: 15px!important;
    font-weight: 500;
    font-size: 16px;
}

.ui-dialog .ui-dialog-content {
    border: 0;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 15px;
    background: white;
    color: #73879C!important;
    min-height: auto!important;
}

.ui-dialog .ui-dialog-buttonpane {
    border: 0;
    margin-top: 0;
    padding: 15px;
}

.ui-dialog .ui-dialog-buttonpane button {
    margin: 0 5px 5px 5px;
}

.ui-dialog .ui-dialog-titlebar-close {
    right: 15px;
}


/*datepicker*/

.ui-datepicker {
    font-size: 13px;
    padding: 0;
    color: #73879c;
    width: auto!important;
    z-index: 20000!important;
}

.ui-datepicker-header {
    line-height: 28px;
    text-align: center;
    color: #fff;
    background: #1ABB9C;
    border-radius: 0;
    border: none;
    padding: 0!important;
}

.ui-datepicker-calendar thead tr {
    line-height: 14px;
    font-weight: bold;
}

.ui-datepicker-calendar>thead>tr>th, .ui-datepicker-calendar>tbody>tr>th, .ui-datepicker-calendar>tfoot>tr>th, .ui-datepicker-calendar>thead>tr>td, .ui-datepicker-calendar>tbody>tr>td, .ui-datepicker-calendar>tfoot>tr>td {
    padding: 5px 7px;
    text-align: center;
}

.ui-datepicker-calendar>tbody>tr>td a {
    border: none!important;
    background: none!important;
    color: inherit!important;
    text-align: center;
}

.ui-datepicker-week-col {
    font-weight: bold;
}

.ui-datepicker-calendar td .ui-state-hover, .ui-datepicker-calendar .ui-state-highlight {
    background: none!important;
    color: inherit!important;
}

.ui-datepicker-calendar .ui-datepicker-today {
    border-radius: 4px;
    border: 1px dashed #536a7f!important;
}

.ui-datepicker-calendar .ui-datepicker-today.ui-datepicker-current-day {
    border-radius: 4px;
    border: none!important;
}

.ui-datepicker-calendar .ui-datepicker-current-day {
    border-radius: 4px;
    background: #536a7f!important;
    color: white!important;
}

.ui-datepicker-calendar td:hover {
    border-radius: 4px;
    background: #eee!important;
    color: inherit!important;
}

.ui-datepicker-calendar tbody tr:last-child td {
    padding-bottom: 10px;
}

.ui-datepicker .ui-datepicker-title select {
    background: transparent;
    box-shadow: none;
    border-color: white;
    margin: 5px;
    padding: 3px 5px;
    width: auto;
}

.ui-datepicker .ui-datepicker-title option {
    background: #1ABB9C;
}

.ui-datepicker table th:hover {
    color: inherit;
    opacity: 1;
}

.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input {
    border-bottom-color: #73879c;
    background: none;
    color: inherit;
    border: none;
    outline: none;
    border-bottom: solid 1px #555;
    width: 95%;
}

.ui-datepicker-header .ui-state-hover, .ui-datepicker-header .ui-state-focus {
    border: none;
    background: none;
}

.ui-datepicker .ui-datepicker-buttonpane button {
    border-radius: 3px;
    color: #fff;
    background: #337ab7;
    border: 1px solid transparent;
    border-color: #2e6da4;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    opacity: 1;
    font-family: inherit;
}

.ui-datepicker .ui-datepicker-buttonpane button:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
}

.ui-timepicker-div .ui_tpicker_unit_hide {
    display: none;
}

.ui-timepicker-div dl dt {
    float: left;
    clear: left;
    padding: 0 0 0 5px;
}

.ui-timepicker-div dl dd {
    margin: 0 10px 10px 40%;
}

.modal-title {
    display: inline-block;
}

.modal-footer .btn+.btn {
    margin-bottom: 5px;
}


/* All notifications*/

.allNotifications {
    position: absolute;
    left: 230px;
    top: 0;
    z-index: 2500;
    display: none;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    background-color: white;
    height: 100vh;
    overflow-y: scroll;
}

.allNotifications-wrapper {
    height: 100vh;
    width: 296px;
    padding: 20px 16px 20px 16px;
}

.allNotifications-wrapper .head {
    padding-bottom: 20px;
}

.allNotifications-wrapper .head * {
    display: inline-block;
}

.allNotifications-wrapper .head p {
    font-size: 15px;
    padding-left: 5px;
}

.allNotifications-content ul {
    list-style: none;
    padding: 0;
}

.allNotifications-content ul li {
    width: 265px;
    padding-left: 15px;
    position: relative;
}

.allNotifications-content ul li:before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0;
}

.allNotifications-content ul li.info:before {
    background-color: rgb(52, 152, 219);
}

.allNotifications-content ul li.success:before, .allNotifications-content ul li.check:before {
    background-color: rgb(38, 185, 154);
}

.allNotifications-content ul li.error:before, .allNotifications-content ul li.ban:before, .allNotifications-content ul li.danger:before {
    background-color: rgb(231, 76, 60);
}

.allNotifications-content ul .ln_solid {
    margin: 10px 0;
}

.notif-btns {
    padding: 5px;
    font-size: 20px;
}

.darkroom.form-group {
    margin-bottom: 0;
}
.darkroom.form-group label {
    padding: 0;
}
.img-margin {
    margin-bottom: 10px;
}

.darkroom-image_preview {
    padding-top: 0!important;
    line-height: 0;
}

.darkroom-image_fullpreview {
    /*margin: 10px 0;*/
}

.table-top-actions .search {
    margin-right: 0;
}

.ico-in-form {
    font-size: 18px;
}

.elfinder-button-search input[id*='elfinder'] {
    width: auto;
    height: auto;
    vertical-align: text-bottom;
    margin-right: 3px;
}

.elfinder-button-search label {
    margin-right: 10px;
}

.elfinder-button-search .elfinder-button-menu div:nth-child(2) {
    border-top: 1px solid #ececec;
}

.readonly-select {
   background-color:#eeeeee;
   cursor:not-allowed;
}
.readonly-select-button {
   cursor:not-allowed;
}

.mCustomScrollBox {
    width: 375px;
}

.cm-s-monokai span.cm-variable.cm-latte {
    color: #ae81ff;
}

.text-default {
    color: #777;
}

.text-orange {
    color: orange;
}

.guion {
    float: left;
    margin-left: -3px;
    margin-right: -3px;
    font-size: 20px;
}


/* ================================================== side dialog =================================================== */


.nais-sd-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 1002;
    display: none;
}

.nais-sd-window {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    border: 1px solid grey;
    box-shadow: 0 0 10px black;
    width: 65%;
    min-width: 600px;
    z-index: 1003;
    display: none;
}

.nais-sd-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    background-color: white;
}

.nais-sd-close-button {
    position: absolute;
    left: -24px;
    top: 50%;
    cursor: pointer;
    width: 24px;
    height: 70px;
    transform: translateY(-50%);
    background-color: #2a3f54;
    color: white;
    text-align: center;
    line-height: 70px;
    box-shadow: 0 0 10px black;
}

.btn-disabled, .btn-disabled:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    cursor: initial;
}

.dialogelfinder {
    z-index: 999999 !important;
}

.sidebar-menu #frm-logoutForm .ln_solid {
    border: 0;
    margin: 0;
    height: auto;
}

#filter-form .sp-container {
    z-index: inherit;
}

.badge {
    margin-top: 4px !important;
}


.datagridCountdown {
    padding: 1px 0 0 8px;
}

.datagridCountdown input {
    font-size: 11px;
}

.select-refresh-interval {
    margin-top: 10px;
}

.select-refresh-interval .select2 {
    float: right;
    width: auto !important;
}

div.parentCheckbox.indeterminate div.icheckbox_flat-green {
    background: url("../images/indeterminate.png") no-repeat top left;
    opacity: 0.5;
}

div.refreshCounter {
    display: inline;
}

html {
    height: 100%;
}
body {
    height: 100%;
    min-height: 100%;
}

.nav_menu {
    margin-bottom: 0;
}

.right_col {
    position: relative;
}

.container.body {
    position: relative;
    min-height: 100%;
    padding-bottom: 35px;
}

footer {
    padding: 8px 20px;
    height: 35px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 235px;
}

.actionSuffix-count {
    position: relative;
    background: rgba(0,160,20,1);
    height: 2rem;
    top: -10px;
    right: 3px;
    width: 2rem;
    text-align: center;
    font-family: Sans-serif;
    line-height: 2rem;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 50%;
    padding: 2px;
    color: white;
    border: 1px solid green;
}
div.fancyHelp .fancybox-content {
    background: transparent !important;
}

div.fancyHelp .fancybox-slide {
    padding: 0;
    overflow: hidden !important;
}


div.chapterLink {
    padding: 20px;
    border-bottom: 1px solid lightgrey;
}
div.chapterLink .title {
    font-size: 20px; font-weight: 700; margin-bottom: 5px; transition: font-size .3s cubic-bezier(0.58, 0, 0.22, 1);
}
div.chapterLink .title sup{
    top: -.75em; font-size: 50%;
}
div.chapterLink .title .tagTitle {
    top: -.75em; font-size: 70%;
}
div.chapterLink .title .num {
    margin-right: 15px;
}

.helpBreadCrumbs {
    font-size: 12px; opacity: 0.8
}
.resultDescription {
    font-size: 15px;
    display:block;
    text-align: justify;
}
.resultGroupDescription{
    margin-left: 30px;
    padding: 10px;
    font-size: 15px;
    text-align: justify;
}
.fulltextHighlighted{
    background-color: rgba(255, 165, 0, 0.3);
    padding: 2px;
}